import { createContext, useContext } from 'react'

export const I18nContext = createContext({})
const useTranslations = (pathPrefix?: string) => {
  const translations = useContext<TranslationMap>(I18nContext)

  return (path: string) => {
    let pathSections: string[] = []
    if (pathPrefix) {
      pathSections = `${pathPrefix}.${path}`.split('.')
    } else {
      pathSections = path.split('.')
    }

    let pointer: any = translations
    for (const pathSection of pathSections) {
      if (!pointer[pathSection]) {
        break
      }
      pointer = pointer[pathSection]
    }
    const translationIsFound = typeof pointer === 'string'

    if (!translationIsFound) {
      return path
    }

    return pointer
  }
}
export default useTranslations

export const I18nProvider = ({ children, messages }: any) => {
  return <I18nContext.Provider value={messages}>{children}</I18nContext.Provider>
}

type TranslationMap = {
  [key: string]: string | TranslationMap
}
