import Head from 'next/head'
import type { AppProps } from 'next/app'

import '../styles/reset.css'
import '../styles/globals.css'
import { I18nProvider } from '../components/I18nProvider/I18nProvider'

function App({ Component, pageProps }: AppProps) {
  const hostname = process.env.HOSTNAME ?? ''
  const title = pageProps?.messages?.header?.title ?? 'Boxy'
  const metaDescriptionContent =
    pageProps?.messages?.header?.meta?.description ?? 'Boxy fullfillment services'

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
        <meta name="description" content={metaDescriptionContent}></meta>
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://www.boxygroup.hu" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={metaDescriptionContent} />
        <meta property="og:image" content={hostname + '/boxy-logo-1200x630.png'} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Logo" />
        <meta property="og:locale" content="hu_HU" />
      </Head>
      <I18nProvider messages={pageProps.messages}>
        <Component {...pageProps} />
      </I18nProvider>
    </>
  )
}

export default App
